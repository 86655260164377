import React from 'react';
import './Portfolio.css';

const MSR_videos = [
  {
    src: '/videos/MSR_Aurora.mp4',
  },
  {
    src: '/videos/MSR_Lookout.mp4',
  },
];

const LS_videos = [
  {
    src: '/videos/LS_Aurora.mp4',
  },
  {
    src: '/videos/LS_2.mp4',
  },
];

const DJI_videos = [
  {
    src: '/videos/dji_1.mp4',
  },
];

function VideoPlayer({ src, title, description }) {
  return (
    <div className="video-container">
      <video controls className="video-player">
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

function Portfolio() {
  return (
    <>
      <h3>MSR</h3>
      <div className="video-grid">
        {MSR_videos.map((video, index) => (
          <VideoPlayer
            key={index}
            src={video.src}
            title={video.title}
            description={video.description}
          />
        ))}
      </div>
      <h3>Life Straw</h3>
      <div className="video-grid">
        {LS_videos.map((video, index) => (
          <VideoPlayer
            key={index}
            src={video.src}
            title={video.title}
            description={video.description}
          />
        ))}
      </div>
      <h3>DJI Pocket Osmo 3</h3>
      <div className="video-grid">
        {DJI_videos.map((video, index) => (
          <VideoPlayer
            key={index}
            src={video.src}
            title={video.title}
            description={video.description}
          />
        ))}
      </div>
    </>
  );
}

export default Portfolio;
