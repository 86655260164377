// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Link } from 'react-router-dom';
import BucketList from './components/BucketList';
import Portfolio from './components/Portfolio';
import { slide as Menu } from 'react-burger-menu';
import './App.css';

let styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '24px',
    height: '24px',
    left: '24px',
    top: '24px'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

function Header() {
  const location = useLocation();
  switch (location.pathname) {
    case '/':
      return (
        <header className="App-header">
          <h1>💯🪣 Corporate 100: Bucket List! 🐈‍⬛🤷🏻‍♂️</h1>
          <p>100 Things I Want to do Before I Become a Corporate Slave!</p>
        </header>
      );
    case '/portfolio':
      return (
        <header className="App-header">
          <h1>Portfolio</h1>
        </header>
      );
    default:
      return (
        <header className="App-header">
          <h1>💯🪣 Corporate 100: Bucket List! 🐈‍⬛🤷🏻‍♂️</h1>
          <p>100 Things I Want to do Before I Become a Corporate Slave!</p>
        </header>
      );
  }
}

function App() {
  return (
    <Router>
      <div className="App">
        <Menu styles={styles}>
          <Link to="/">Bucket List</Link>
          <Link to="/portfolio">Portfolio</Link>
        </Menu>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<BucketList />} />
            <Route path="/portfolio" element={<Portfolio />} />
          </Routes>
        </main>

        <footer>
          <p>© 2024 Corporate100 LLC</p>
        </footer>
      </div>
    </Router >
  );
}

export default App;
